import React from 'react';
import BrandSettings from 'components/data/BrandSettings';
import Icon from 'components/ui-components-v2/Icon';
import { IMetaData } from '../types/meta-preview.type';
import facebookAdsOnReelsImage from '../images/facebook-ads-on-reels.png';
import placeholderImage from '../../../images/social-preview-placeholder.png';

import '../styles/facebook-ads-on-reels-preview.scss';

const FacebookAdsOnReelsPreview: React.FC<IMetaData> = ({ items }) => {
    const { name = '', asset } = items?.[0] || {};
    const advertiser = BrandSettings.getAdvertiserData();

    return (
        <div className="facebook-ads-on-reels">
            <img className="facebook-ads-on-reels__image" src={facebookAdsOnReelsImage} alt="Ads on Reels" />
            <div className="facebook-ads-on-reels__content">
                <div className="facebook-ads-on-reels__content__asset-wrapper">{asset || <img src={placeholderImage} alt="Placeholder" />}</div>

                <div className="facebook-ads-on-reels__content__text">
                    <div className="facebook-ads-on-reels__content__text__name">{name}</div>
                    <div className="facebook-ads-on-reels__content__text__advertiser">{advertiser?.name || 'Cape'} • Sponsored</div>
                </div>
                <Icon className="facebook-ads-on-reels__content__icon">keyboard_arrow_down</Icon>
            </div>
        </div>
    );
};

export default FacebookAdsOnReelsPreview;
