import React from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IMetaData } from '../types/meta-preview.type';
import facebookMarketplaceHeader from '../images/facebook-marketplace-header.png';
import facebookMarketplaceFooter from '../images/facebook-marketplace-footer.png';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';
import SponsoredHeader from './sponsored-header';

import '../styles/facebook-marketplace-preview.scss';

const FacebookMarketplacePreview: React.FC<IMetaData> = ({ message, cta, items }) => {
    const { name, link = '', asset } = items[0] || {};

    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    return (
        <div className="facebook-marketplace">
            <div className="facebook-marketplace__header">
                <img src={facebookMarketplaceHeader} alt="Facebook Header" />
            </div>
            <div className="facebook-marketplace__content">
                <div className="facebook-marketplace__content__container">
                    <SponsoredHeader hasMenu={false} />
                    <div className="facebook-marketplace__content__container__asset-container">
                        {asset || (
                            <div className="facebook-marketplace__content__placeholder">
                                <Typography variant="body2" color="textSecondary" className="facebook-marketplace__content__placeholder__text">
                                    {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                                </Typography>
                            </div>
                        )}
                    </div>
                    <div className="facebook-marketplace__content__container__text">
                        <div className="facebook-marketplace__content__container__text__name">{name}</div>
                        <div className="facebook-marketplace__content__container__text__message">{message}</div>
                        <a href={formattedLink} target="_blank" rel="noopener noreferrer" className="facebook-marketplace__content__container__text__cta">
                            {cta}
                        </a>
                    </div>
                </div>
                <div className="facebook-marketplace__content__container">
                    <div className="facebook-marketplace__content__sponsored">
                        <div className="facebook-marketplace__content__sponsored__company"></div>
                    </div>

                    <div className="facebook-marketplace__content__container__asset-container">
                        <div className="facebook-marketplace__content__placeholder"></div>
                    </div>

                    <div className="facebook-marketplace__content__container__text">
                        <div className="facebook-marketplace__content__container__text__placeholder-lines">
                            <div className="facebook-marketplace__content__container__text__placeholder-line short"></div>
                            <div className="facebook-marketplace__content__container__text__placeholder-line medium"></div>
                            <div className="facebook-marketplace__content__container__text__placeholder-line"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="facebook-marketplace__footer">
                <img src={facebookMarketplaceFooter} alt="Facebook Footer" />
            </div>
        </div>
    );
};

export default FacebookMarketplacePreview;
