import React, { useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useIsInView } from 'hooks/useIsInView';
import classNames from 'classnames';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import { CalculatedGridItem } from 'helpers/arrangeItemsInGrid';
import { CreativeV2Enriched } from '../creative-editor/types/creativeV2.type';
import DynamicAssetV2 from '../dynamic-asset-v2';
import ItemWrapperHeader from './components/item-wrapper-header';
import './styles/main.scss';

interface Props {
    creativeGridItem: CalculatedGridItem<CreativeV2Enriched>;
    zoom?: number;
    hideOutsideView?: boolean;
    iframeOverwrites?: IframeOverwrites<IframeData>;
    headerComponent?: React.ReactElement;
    editable?: boolean;
    hideOverlay?: boolean;
}

const CreativeItemWrapper: React.FC<Props> = ({
    creativeGridItem,
    zoom = 1,
    hideOutsideView = true,
    iframeOverwrites,
    headerComponent,
    editable = false,
    hideOverlay = false
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const isInView = useIsInView(containerRef);

    const getAdditionalItemStyle = () => ({
        transition: zoom === 1 ? 'box-shadow 0.2s, transform 0.2s' : 'box-shadow 0s, transform 0s',
        borderRadius: 8 / zoom
    });

    // Additional styling for the creative itself, dependend on the creative settings
    const getAdditionalCreativeStyle = () => {
        return {
            width: creativeGridItem.width * creativeGridItem.scale || 1,
            height: creativeGridItem.height * creativeGridItem.scale || 1
        };
    };

    return (
        <div className="creative-item-wrapper" ref={containerRef} style={getAdditionalItemStyle()}>
            <ItemWrapperHeader zoom={zoom} creativeGridItem={creativeGridItem} headerComponent={headerComponent} />
            <ClickAwayListener onClickAway={() => setIsFocused(false)}>
                <div className="creative-item-wrapper__creative" style={getAdditionalCreativeStyle()}>
                    {hideOutsideView && !isInView && (
                        <div className="creative-item-wrapper__creative__not-in-view">
                            <CircularProgress />
                        </div>
                    )}
                    {(!hideOutsideView || (hideOutsideView && isInView)) && (
                        <DynamicAssetV2
                            creative={creativeGridItem.item}
                            formatKey={creativeGridItem.itemKey}
                            scale={creativeGridItem.scale}
                            iframeOverwrites={iframeOverwrites}
                        />
                    )}
                    {!hideOverlay && (
                        <div
                            className={classNames('creative-item-wrapper__creative__overlay', {
                                'creative-item-wrapper__creative__overlay--is-focused': isFocused
                            })}
                            onClick={() => setIsFocused(editable)}
                        />
                    )}
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default CreativeItemWrapper;
