import React from 'react';
import { IMetaData } from '../types/meta-preview.type';
import facebookRightColumnPanel from '../images/facebook-right-column-panel.png';
import facebookRightColumnFooter from '../images/facebook-right-column-footer.png';
import FacebookRightColumnAd from './facebook-right-column-ad';

import '../styles/facebook-right-column-preview.scss';

const FacebookRightColumnPreview: React.FC<IMetaData> = ({ message, items }) => {
    return (
        <div className="facebook-right-column">
            <div className="facebook-right-column__left">
                <img src={facebookRightColumnPanel} alt="Facebook Panel" />
            </div>

            <div className="facebook-right-column__right">
                <FacebookRightColumnAd message={message} items={items} />
                <div className="facebook-right-column__right__footer">
                    <img src={facebookRightColumnFooter} alt="Facebook Footer" />
                </div>
            </div>
        </div>
    );
};

export default FacebookRightColumnPreview;
