import React, { useRef, useState, useEffect } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';
import { IMetaAdItem } from '../types/meta-preview.type';

import '../styles/facebook-carousel-items.scss';

interface FacebookCarouselAdProps {
    items: IMetaAdItem[];
    cta: string;
    link: string;
}

const FacebookCarouselItems: React.FC<FacebookCarouselAdProps> = ({ items, cta, link }) => {
    const [scrollLeft, setScrollLeft] = useState(0);
    const facebookCarouselRef = useRef<HTMLDivElement>(null);
    // we use the selected frame index to swipe when clicking on a frame number
    const { selectedFrame } = useAdSetupContext();
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    const updateScrollPosition = () => {
        if (facebookCarouselRef.current) {
            setScrollLeft(facebookCarouselRef.current.scrollLeft);
        }
    };

    const swipe = (direction: string) => {
        const element = facebookCarouselRef.current;
        const distance = 240;

        if (element) {
            if (direction === 'right') {
                element.scrollLeft += distance;
                setScrollLeft(scrollLeft + distance);
            } else {
                element.scrollLeft -= distance;
                setScrollLeft(scrollLeft - distance);
            }
        }
    };

    const swipeOnFrameNr = (selectedFrame: number) => {
        const element = facebookCarouselRef.current;
        const distance = 240;

        if (element) {
            setScrollLeft(selectedFrame * distance);
            element.scrollLeft = selectedFrame * distance;
        }
    };

    useEffect(() => {
        swipeOnFrameNr(selectedFrame);
    }, [selectedFrame]);

    useEffect(() => {
        const handleScroll = () => updateScrollPosition();
        const element = facebookCarouselRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => element.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <div className="facebook-carousel-section">
            <div className="facebook-carousel-section__carousel" ref={facebookCarouselRef}>
                {items.map(({ name, description, asset }, index) => (
                    <div key={index} className="facebook-carousel-section__carousel__item">
                        <div className="facebook-carousel-section__carousel__item__post">
                            <div className="facebook-carousel-section__carousel__item__asset-wrapper">
                                <div className="facebook-carousel-section__carousel__item__asset-center">
                                    {asset ? (
                                        asset
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            className="facebook-carousel-section__carousel__item__asset-center__placeholder">
                                            {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                            <div className="facebook-carousel-section__carousel__item__info">
                                <div className="facebook-carousel-section__carousel__item__text-wrapper">
                                    <div className="facebook-carousel-section__carousel__item__name">{name}</div>
                                    <div className="facebook-carousel-section__carousel__item__description">{description}</div>
                                </div>
                                {cta && (
                                    <div className="facebook-carousel-section__carousel__item__cta-wrapper">
                                        <a href={formattedLink} target="_blank" rel="noopener noreferrer">
                                            <button className="facebook-carousel-section__carousel__item__cta-button">{cta}</button>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {scrollLeft > 0 && (
                <div className="facebook-carousel-section__arrow-left" onClick={() => swipe('left')}>
                    <Icon fontSize="large" className="facebook-carousel-section__arrow-left__arrow-icon">
                        chevron_left
                    </Icon>
                </div>
            )}
            {items.length > 0 && (facebookCarouselRef.current?.scrollLeft || 0) < (items.length - 1) * 240 - 75 && (
                <div className="facebook-carousel-section__arrow-right" onClick={() => swipe('right')}>
                    <Icon fontSize="large" className="facebook-carousel-section__arrow-right__arrow-icon">
                        chevron_right
                    </Icon>
                </div>
            )}
        </div>
    );
};

export default FacebookCarouselItems;
