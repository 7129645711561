import React, { useState } from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IMetaData } from '../types/meta-preview.type';
import facebookSearchHeader from '../images/facebook-search-header.png';
import SponsoredHeader from './sponsored-header';
import FacebookFeedDetails from './facebook-feed-details';
import MetaHelpers from '../helpers/meta.helpers';
import facebookFeedFooterImage from '../images/facebook-feed-footer.png';

import '../styles/facebook-search-preview.scss';

const FacebookSearchPreview: React.FC<IMetaData> = ({ message, cta, items }) => {
    const { name = '', description = '', link = '', caption = '', asset } = items?.[0] || {};
    const [showFullText, setShowFullText] = useState(false);

    const handleShowFullText = () => {
        setShowFullText(true);
    };
    return (
        <div className="facebook-search">
            <div className="facebook-search__header">
                <img src={facebookSearchHeader} alt="Facebook Header" />
            </div>
            <div className="facebook-search__content">
                <SponsoredHeader />
                <div className="facebook-search__content__message">{MetaHelpers.transformText(message || '', showFullText, handleShowFullText, 208)}</div>
                {asset ? (
                    <div className="facebook-search__content__asset-wrapper">{asset}</div>
                ) : (
                    <Typography variant="body2" color="textSecondary" className="facebook-search__content__placeholder">
                        {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                    </Typography>
                )}
                <FacebookFeedDetails name={name} description={description} caption={caption} link={link} cta={cta} backgroundColor="#ffffff" />
                <div className="facebook-search__footer">
                    <img src={facebookFeedFooterImage} alt="Facebook Footer" />
                </div>
            </div>
        </div>
    );
};

export default FacebookSearchPreview;
