import React, { useEffect, useState } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IMetaData } from '../types/meta-preview.type';
import InstagramFeedDetails from './instagram-feed-details';

import instagramHeaderImage from '../images/instagram-header.png';
import SponsoredHeader from './sponsored-header';

import '../styles/instagram-feed-multiple-preview.scss';

const InstagramFeedMultiplePreview: React.FC<IMetaData> = ({ message, cta, items }) => {
    const { link = '' } = items[0] || {};

    const [currentIndex, setCurrentIndex] = useState(0);
    const { selectedFrame } = useAdSetupContext();

    const totalItems = items.length;

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
    };

    const goToSlide = (index: number) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        setCurrentIndex(selectedFrame);
    }, [selectedFrame]);

    return (
        <div className="instagram-feed-multiple">
            <div className="instagram-feed-multiple__header">
                <img src={instagramHeaderImage} alt="Instagram Header" />
            </div>
            <SponsoredHeader />
            <div className="instagram-feed-multiple__content">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`instagram-feed-multiple__content__asset-wrapper ${index === currentIndex ? 'active' : ''}`}
                        style={{ transform: `translateX(${-currentIndex * 100}%)` }}>
                        {item.asset ? (
                            item.asset
                        ) : (
                            <Typography variant="body2" color="textSecondary" className="instagram-feed-multiple__content__placeholder">
                                {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                            </Typography>
                        )}
                    </div>
                ))}
                {totalItems > 1 && currentIndex > 0 && (
                    <div className="instagram-feed-multiple__arrow-left" onClick={prevSlide}>
                        <Icon fontSize="large">chevron_left</Icon>
                    </div>
                )}
                {totalItems > 1 && currentIndex < totalItems - 1 && (
                    <div className="instagram-feed-multiple__arrow-right" onClick={nextSlide}>
                        <Icon fontSize="large">chevron_right</Icon>
                    </div>
                )}
            </div>
            <div className="instagram-feed-multiple__details">
                <div className="instagram-feed-multiple__details__pagination">
                    {items.map((_, index) => (
                        <span
                            key={index}
                            className={`instagram-feed-multiple__details__pagination__dot ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => goToSlide(index)}></span>
                    ))}
                </div>
                <InstagramFeedDetails cta={cta} message={message} link={link} />
            </div>
        </div>
    );
};

export default InstagramFeedMultiplePreview;
