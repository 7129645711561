import React from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IMetaAdItem } from '../types/meta-preview.type';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';

import '../styles/facebook-right-column-ad.scss';

interface FacebookRightColumnAdProps {
    message: string;
    items: IMetaAdItem[];
}

const FacebookRightColumnAd: React.FC<FacebookRightColumnAdProps> = ({ message, items }) => {
    const { link = '', caption = '', asset } = items?.[0] || {};
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    return (
        <div className="facebook-right-column-ad">
            <div className="facebook-right-column-ad__sponsored">Sponsored</div>
            <div className="facebook-right-column-ad__content">
                <div className="facebook-right-column-ad__content__asset-wrapper">
                    {asset || (
                        <Typography variant="body2" color="textSecondary" className="facebook-right-column-ad__content__placeholder">
                            {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                        </Typography>
                    )}
                </div>
                <div className="facebook-right-column-ad__content__text">
                    <div className="facebook-right-column-ad__content__text__message">{message}</div>
                    <a href={formattedLink} target="_blank" rel="noopener noreferrer" className="facebook-right-column-ad__content__text__caption">
                        {caption}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FacebookRightColumnAd;
