import React from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IMetaData } from '../types/meta-preview.type';
import instagramSearchHeader from '../images/instagram-search-header.png';
import instagramSearchFooter from '../images/instagram-search-footer.png';

import '../styles/instagram-search-preview.scss';

const InstagramSearchPreview: React.FC<IMetaData> = ({ items }) => {
    const { asset } = items[0] || {};

    return (
        <div className="instagram-search">
            <div className="instagram-search__header">
                <img src={instagramSearchHeader} alt="Instagram Header" />
            </div>

            <div className="instagram-search__grid">
                {Array.from({ length: 3 }, (_, index) => (
                    <div key={`square-${index}`} className="instagram-search__grid__item__grey-square"></div>
                ))}
                <div className="instagram-search__grid__item__asset">
                    {asset ? (
                        <>
                            {asset}
                            <div className="instagram-search__grid__item__asset__sponsored">Sponsored</div>
                        </>
                    ) : (
                        <Typography variant="body2" color="textSecondary" className="instagram-search__grid__item__asset__placeholder">
                            {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                        </Typography>
                    )}
                </div>
                {Array.from({ length: 32 }, (_, index) => (
                    <div key={`square-${index + 3}`} className="instagram-search__grid__item__grey-square"></div>
                ))}
            </div>

            <div className="instagram-search__footer">
                <img src={instagramSearchFooter} alt="Instagram Footer" />
            </div>
        </div>
    );
};

export default InstagramSearchPreview;
