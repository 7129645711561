import React from 'react';
import { ILinkedinData } from '../types/linkedin-preview.type';

const LinkedinFeedPreview: React.FC<ILinkedinData> = ({ message, cta, items }) => {
    return (
        <div className="linkedin-feed-preview">
            <p>{message}</p>
            <button>{cta}</button>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        <p>{item.name}</p>
                        <p>{item.description}</p>
                        <p>{item.link}</p>
                        <p>{item.caption}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LinkedinFeedPreview;
