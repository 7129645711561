import React, { useState } from 'react';
import { IMetaData } from '../types/meta-preview.type';
import SponsoredHeader from './sponsored-header';
import commentsIcon from '../images/reels-commnents.svg';
import shareIcon from '../images/reels-forward.svg';
import likeIcon from '../images/reels-like.svg';
import moreIcon from '../images/reels-more.svg';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';

import '../styles/facebook-reels-preview.scss';

const FacebookReelsPreview: React.FC<IMetaData> = ({ message, cta, items }) => {
    const { link = '', asset } = items[0] || {};
    const [isHovered, setIsHovered] = useState(false);
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    return (
        <div className="facebook-reels" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="facebook-reels__asset-wrapper">{asset}</div>
            <div className="facebook-reels__icons">
                <div className="facebook-reels__icon">
                    <img src={likeIcon} alt="Like" />
                </div>
                <div className="facebook-reels__icon">
                    <img src={commentsIcon} alt="Comments" />
                </div>
                <div className="facebook-reels__icon">
                    <img src={shareIcon} alt="Share" />
                </div>
                <div className="facebook-reels__icon">
                    <img src={moreIcon} alt="More" />
                </div>
            </div>
            <div className={`facebook-reels__content ${isHovered ? 'hidden' : ''}`}>
                <SponsoredHeader hasMenu={false} dark transparent />
                <div className="facebook-reels__content__message">{message}</div>
                <a href={formattedLink} target="_blank" rel="noopener noreferrer" className="facebook-reels__content__cta-link">
                    <button className="facebook-reels__content__cta-button">{cta}</button>
                </a>
            </div>
            <div className={`facebook-reels__gradient-bottom ${isHovered ? 'hidden' : ''}`}></div>
        </div>
    );
};

export default FacebookReelsPreview;
