import React, { useState } from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IMetaData } from '../types/meta-preview.type';
import facebookFeedVideoFooterImage from '../images/facebook-feed-video-footer.png';
import SponsoredHeader from './sponsored-header';
import MetaHelpers from '../helpers/meta.helpers';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';

import '../styles/facebook-feed-video-preview.scss';

const FacebookFeedVideoPreview: React.FC<IMetaData> = ({ message, cta, items }) => {
    const { name = '', description = '', link = '', asset } = items?.[0] || {};
    const [showFullText, setShowFullText] = useState(false);
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    const handleShowFullText = () => {
        setShowFullText(true);
    };

    return (
        <div className="facebook-feed-video">
            <div className="facebook-feed-video__header">Suggested video</div>
            <div className="facebook-feed-video__content">
                <SponsoredHeader dark={true} />
                <div className="facebook-feed-video__content__message">{MetaHelpers.transformText(message || '', showFullText, handleShowFullText, 208)}</div>
                {asset ? (
                    <div className="facebook-feed-video__content__asset-wrapper">{asset}</div>
                ) : (
                    <Typography variant="body2" color="textSecondary" className="facebook-feed-video__content__placeholder">
                        {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                    </Typography>
                )}
                <div className="facebook-feed-video__content__text">
                    <div className="facebook-feed-video__content__text__name">{name}</div>
                    <div className="facebook-feed-video__content__text__description">
                        {MetaHelpers.transformText(description || '', showFullText, handleShowFullText, 70)}
                    </div>
                    <a href={formattedLink} target="_blank" rel="noopener noreferrer">
                        <button className="facebook-feed-video__content__text__cta-button">{cta}</button>
                    </a>
                </div>
            </div>
            <div className="facebook-feed-video__footer">
                <img src={facebookFeedVideoFooterImage} alt="Facebook Footer" />
            </div>
        </div>
    );
};

export default FacebookFeedVideoPreview;
