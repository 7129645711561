import React, { useState } from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IMetaData } from '../types/meta-preview.type';
import facebookFeedVideoFooterImage from '../images/facebook-feed-video-footer.png';
import SponsoredHeader from './sponsored-header';
import MetaHelpers from '../helpers/meta.helpers';

import '../styles/facebook-instream-video-preview.scss';

const FacebookInstreamVideoPreview: React.FC<IMetaData> = ({ message, cta, items }) => {
    const { name = '', description = '', asset } = items?.[0] || {};
    const [showFullText, setShowFullText] = useState(false);

    const handleShowFullText = () => {
        setShowFullText(true);
    };

    return (
        <div className="facebook-instream-video">
            <div className="facebook-instream-video__header">Suggested video</div>
            <div className="facebook-instream-video__content">
                <SponsoredHeader dark={true} />
                <div className="facebook-instream-video__content__message">
                    {MetaHelpers.transformText(message || '', showFullText, handleShowFullText, 208)}
                </div>
                {asset ? (
                    <div className="facebook-instream-video__content__asset-wrapper">{asset}</div>
                ) : (
                    <Typography variant="body2" color="textSecondary" className="facebook-instream-video__content__placeholder">
                        {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                    </Typography>
                )}
                <div className="facebook-instream-video__content__text">
                    <div className="facebook-instream-video__content__text__name">{name}</div>
                    <div className="facebook-instream-video__content__text__description">
                        {MetaHelpers.transformText(description || '', showFullText, handleShowFullText, 70)}
                    </div>
                    <button className="facebook-instream-video__content__text__cta-button">{cta}</button>
                </div>
            </div>
            <div className="facebook-instream-video__footer">
                <img src={facebookFeedVideoFooterImage} alt="Facebook Footer" />
            </div>
        </div>
    );
};

export default FacebookInstreamVideoPreview;
