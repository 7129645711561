import React, { useState } from 'react';
import Button from 'components/ui-components-v2/Button';
import { IMetaData } from '../types/meta-preview.type';
import SponsoredHeader from './sponsored-header';
import actionRight from '../images/insta-reels-actions-right.svg';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';

import '../styles/instagram-reels-preview.scss';

const InstagramReelsPreview: React.FC<IMetaData> = ({ message, cta, items }) => {
    const { link = '', asset } = items[0] || {};
    const [isHovered, setIsHovered] = useState(false);
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    return (
        <div className="instagram-reels" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="instagram-reels__asset-wrapper">{asset}</div>
            <div className="instagram-reels__icons">
                <img src={actionRight} alt="Actions right" />
            </div>
            <div className={`instagram-reels__content ${isHovered ? 'hidden' : ''}`}>
                <SponsoredHeader hasMenu={false} dark transparent />
                <a href={formattedLink} target="_blank" rel="noopener noreferrer">
                    <Button className="instagram-reels__content__cta-button">{cta}</Button>
                </a>
                <div className="instagram-reels__content__message">{message}</div>
            </div>
            <div className={`instagram-reels__gradient-bottom ${isHovered ? 'hidden' : ''}`}></div>
        </div>
    );
};

export default InstagramReelsPreview;
