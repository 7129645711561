import React, { useState, useEffect } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import BrandSettings from 'components/data/BrandSettings';
import instagramActions from '../images/instagram-actions.png';
import MetaHelpers from '../helpers/meta.helpers';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';

import '../styles/instagram-feed-details.scss';

interface InstagramFeedDetailsProps {
    message: string;
    cta: string;
    link: string;
}

const InstagramFeedDetails: React.FC<InstagramFeedDetailsProps> = ({ cta, message, link }) => {
    const advertiser = BrandSettings.getAdvertiserData();
    const [showFullText, setShowFullText] = useState(false);
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    useEffect(() => {
        const timer = setTimeout(() => {
            document.querySelector('.instagram-feed-details__cta')?.classList.add('animate');
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    const handleShowFullText = () => {
        setShowFullText(true);
    };

    return (
        <div className="instagram-feed-details">
            <a href={formattedLink} target="_blank" rel="noopener noreferrer" className="instagram-feed-details__cta-link">
                <div className="instagram-feed-details__cta">
                    <div className="instagram-feed-details__cta__text">{cta}</div>
                    <div className="instagram-feed-details__cta__icon">
                        <Icon>chevron_right</Icon>
                    </div>
                </div>
            </a>
            <hr className="instagram-feed-details__hr" />
            <div className="instagram-feed-details__actions-container">
                <img src={instagramActions} alt="Actions" />
            </div>
            <div className="instagram-feed-details__text">
                <div className="instagram-feed-details__text__username">{advertiser?.name || 'Cape'}</div>
                <div className="instagram-feed-details__text__message">{MetaHelpers.transformMessage(message || '', showFullText, handleShowFullText)}</div>
                <div className="instagram-feed-details__text__view-comments">View all 43 comments</div>
            </div>
        </div>
    );
};

export default InstagramFeedDetails;
