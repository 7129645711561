import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import BrandSettings from 'components/data/BrandSettings';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IProfileMetaData } from '../types/meta-preview.type';
import instagramHeaderImage from '../images/instagram-header.png';
import InstagramFeedDetails from './instagram-feed-details';
import SponsoredHeader from './sponsored-header';

import '../styles/instagram-feed-single-preview.scss';

const InstagramFeedPreview: React.FC<IProfileMetaData> = ({ message, cta, items, isProfile = false }) => {
    const { link = '', asset } = items[0] || {};
    const advertiser = BrandSettings.getAdvertiserData();

    return (
        <div className="instagram-feed-single">
            {isProfile ? (
                <div>
                    <div className="instagram-feed-single__profile-header">
                        <Icon fontSize="large" className="instagram-feed-single__profile-header__icon">
                            chevron_left
                        </Icon>
                        <div className="instagram-feed-single__profile-header__text">
                            <div className="instagram-feed-single__profile-header__text__advertiser">{advertiser?.name || 'Cape'}</div>
                            <div className="instagram-feed-single__profile-header__text__posts">{Translation.get('posts', 'bricks')}</div>
                        </div>
                    </div>
                    <div className="instagram-feed-single__profile-header__line"></div>
                </div>
            ) : (
                <div className="instagram-feed-single__header">
                    <img src={instagramHeaderImage} alt="Instagram Header" />
                </div>
            )}
            <SponsoredHeader />
            <div className="instagram-feed-single__content">
                {asset ? (
                    <div className="instagram-feed-single__content__asset-wrapper">{asset}</div>
                ) : (
                    <Typography variant="body2" color="textSecondary" className="instagram-feed-single__content__placeholder">
                        {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                    </Typography>
                )}
            </div>
            <InstagramFeedDetails cta={cta} message={message} link={link} />
        </div>
    );
};

export default InstagramFeedPreview;
