import React from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { IMetaData } from '../types/meta-preview.type';
import instagramExploreHomeHeader from '../images/instagram-explore-home-header.png';
import instagramExploreHomeFooter from '../images/instagram-explore-home-footer.png';

import '../styles/instagram-explore-home-preview.scss';

const InstagramExploreHomePreview: React.FC<IMetaData> = ({ items }) => {
    const { asset } = items[0] || {};
    const gridItems = new Array(12).fill(null);

    return (
        <div className="instagram-explore-home">
            <div className="instagram-explore-home__header">
                <img src={instagramExploreHomeHeader} alt="Instagram Header" />
            </div>
            <div className="instagram-explore-home__grid">
                {gridItems.map((_, index) => (
                    <div className="instagram-explore-home__grid__item" key={index}>
                        {index === 4 ? (
                            <>
                                {asset ? (
                                    <>
                                        {asset}
                                        <div className="instagram-explore-home__grid__item__sponsored">Sponsored</div>
                                    </>
                                ) : (
                                    <Typography variant="body2" color="textSecondary" className="instagram-explore-home__grid__item__placeholder">
                                        {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                                    </Typography>
                                )}
                            </>
                        ) : null}
                    </div>
                ))}
            </div>
            <div className="instagram-explore-home__footer">
                <img src={instagramExploreHomeFooter} alt="Instagram Explore Footer" />
            </div>
        </div>
    );
};

export default InstagramExploreHomePreview;
