import React, { useState } from 'react';
import { IMetaAdItem, IMetaData } from '../types/meta-preview.type';
import facebookFeedHeaderImage from '../images/facebook-feed-header.png';
import facebookFeedFooterImage from '../images/facebook-feed-footer.png';
import SponsoredHeader from './sponsored-header';
import MetaHelpers from '../helpers/meta.helpers';
import FacebookCarouselItems from './facebook-carousel-items';

import '../styles/facebook-feed-multiple-preview.scss';

const FacebookFeedMultiplePreview: React.FC<IMetaData> = ({ message, cta, items }) => {
    const { link = '' } = items?.[0] || {};
    const [showFullText, setShowFullText] = useState(false);

    const handleShowFullText = () => {
        setShowFullText(true);
    };
    return (
        <div className="facebook-feed-multi">
            <div className="facebook-feed-multi__header">
                <img src={facebookFeedHeaderImage} alt="Facebook Header" />
            </div>
            <div className="facebook-feed-multi__content">
                <SponsoredHeader />
                <div className="facebook-feed-multi__content__message">{MetaHelpers.transformText(message || '', showFullText, handleShowFullText, 208)}</div>

                <FacebookCarouselItems items={items as IMetaAdItem[]} cta={cta} link={link} />
            </div>
            <div className="facebook-feed-multi__footer">
                <img src={facebookFeedFooterImage} alt="Facebook Footer" />
            </div>
        </div>
    );
};

export default FacebookFeedMultiplePreview;
