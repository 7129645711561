import React, { useState, useEffect, useCallback } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import { IMetaData } from '../types/meta-preview.type';
import SponsoredHeader from './sponsored-header';
import arrowUp from '../images/arrow-up-white.svg';
import useProgressTimer from '../helpers/useProgressTimer';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';

import '../styles/facebook-story-preview.scss';

const FacebookStoryPreview: React.FC<IMetaData> = ({ cta, items }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { selectedFrame } = useAdSetupContext();
    const { progress, resetProgress } = useProgressTimer(currentIndex);
    const [isHovered, setIsHovered] = useState(false);
    const { link = '' } = items?.[0] || {};
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    useEffect(() => {
        setCurrentIndex(selectedFrame);
    }, [selectedFrame]);

    const nextSlide = useCallback(() => {
        if (currentIndex < items.length - 1) {
            setCurrentIndex(currentIndex + 1);
            resetProgress();
        }
    }, [currentIndex, items.length, resetProgress]);

    const prevSlide = useCallback(() => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            resetProgress();
        }
    }, [currentIndex, resetProgress]);

    const getProgressValue = useCallback(
        (index: number) => {
            if (index === currentIndex) {
                return progress;
            }
            if (index < currentIndex) {
                return 100;
            }
            return 0;
        },
        [currentIndex, progress]
    );

    return (
        <div className="facebook-story" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="facebook-story__asset-wrapper">{items[currentIndex]?.asset}</div>
            <div className="facebook-story__progress-wrapper">
                {items.map((_, index) => (
                    <LinearProgress key={index} variant="determinate" value={getProgressValue(index)} className="facebook-story__progress" />
                ))}
            </div>
            <div className="facebook-story__header">
                <div className="facebook-story__header__sponsored">
                    <SponsoredHeader transparent dark />
                </div>
                <Icon className="facebook-story__header__close-icon">close</Icon>
            </div>
            <div className={`facebook-story__content ${isHovered ? 'hidden' : ''}`}>
                <img src={arrowUp} alt="Arrow Up" className="facebook-story__content__arrow-icon" />
                <a href={formattedLink} target="_blank" rel="noopener noreferrer">
                    <button className="facebook-story__content__cta-button">{cta}</button>
                </a>
            </div>
            {items.length > 1 && (
                <div className="facebook-story__navigation">
                    {currentIndex > 0 && (
                        <div className="facebook-story__arrow-left" onClick={prevSlide}>
                            <Icon fontSize="large">chevron_left</Icon>
                        </div>
                    )}
                    {currentIndex < items.length - 1 && (
                        <div className="facebook-story__arrow-right" onClick={nextSlide}>
                            <Icon fontSize="large">chevron_right</Icon>
                        </div>
                    )}
                </div>
            )}
            <div className="facebook-story__gradient-top"></div>
            <div className={`facebook-story__gradient-bottom ${isHovered ? 'hidden' : ''}`}></div>
        </div>
    );
};

export default FacebookStoryPreview;
