import React from 'react';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';

import '../styles/facebook-feed-details.scss';

interface FacebookFeedDetailsProps {
    name: string;
    description: string;
    caption: string;
    link: string;
    cta: string;
    backgroundColor?: string;
}

const FacebookFeedDetails: React.FC<FacebookFeedDetailsProps> = ({ name, description, caption, link, cta, backgroundColor }) => {
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    return (
        <div className="facebook-feed-details" style={{ backgroundColor: backgroundColor || '#F6F6F6' }}>
            <div className="facebook-feed-details__left">
                <a href={formattedLink} className="facebook-feed-details__left__caption" target="_blank" rel="noopener noreferrer">
                    {caption || link}
                </a>
                <div className="facebook-feed-details__left__name">{name}</div>
                <div className="facebook-feed-details__left__description">{description}</div>
            </div>
            {cta && (
                <div className="facebook-feed-details__right">
                    <a href={formattedLink} target="_blank" rel="noopener noreferrer">
                        <button className="facebook-feed-details__right__cta-button">{cta}</button>
                    </a>
                </div>
            )}
        </div>
    );
};

export default FacebookFeedDetails;
