import React, { useEffect, useState, useCallback } from 'react';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import Icon from 'components/ui-components-v2/Icon';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import { IMetaData } from '../types/meta-preview.type';
import SponsoredHeader from './sponsored-header';
import arrowUp from '../images/arrow-up-black.svg';
import useProgressTimer from '../helpers/useProgressTimer';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';

import '../styles/instagram-story-preview.scss';

const InstagramStoryPreview: React.FC<IMetaData> = ({ cta, items }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { selectedFrame } = useAdSetupContext();
    const { progress, resetProgress } = useProgressTimer(currentIndex);
    const { link = '' } = items[0] || {};
    const [isHovered, setIsHovered] = useState(false);
    const formattedLink = SocialPreviewsHelpers.formatLink(link);

    const totalItems = items.length;

    useEffect(() => {
        setCurrentIndex(selectedFrame);
    }, [selectedFrame]);

    const nextSlide = useCallback(() => {
        if (currentIndex < items.length - 1) {
            setCurrentIndex(currentIndex + 1);
            resetProgress();
        }
    }, [currentIndex, items.length, resetProgress]);

    const prevSlide = useCallback(() => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            resetProgress();
        }
    }, [currentIndex, resetProgress]);

    const getProgressValue = (index: number) => {
        if (index === currentIndex) {
            return progress;
        }
        if (index < currentIndex) {
            return 100;
        }
        return 0;
    };

    return (
        <div className="instagram-story" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="instagram-story__gradient-top"></div>

            <div className="instagram-story__asset-wrapper">{items[currentIndex]?.asset}</div>
            <div className="instagram-story__progress-wrapper">
                {items.map((_, index) => (
                    <LinearProgress key={index} variant="determinate" value={getProgressValue(index)} className="instagram-story__progress" />
                ))}
            </div>
            <div className="instagram-story__header">
                <div className="instagram-story__header__sponsored">
                    <SponsoredHeader transparent dark />
                </div>
                <Icon className="instagram-story__header__close-icon">close</Icon>
            </div>
            <div className={`instagram-story__content ${isHovered ? 'hidden' : ''}`}>
                <a href={formattedLink} target="_blank" rel="noopener noreferrer" className="instagram-story__content__link">
                    <div className="instagram-story__content__arrow-container">
                        <img src={arrowUp} alt="Arrow Up" className="instagram-story__content__arrow-icon" />
                    </div>
                    <div className="instagram-story__content__cta">{cta}</div>
                </a>
            </div>
            {totalItems > 1 && (
                <div className="instagram-story__navigation">
                    {currentIndex > 0 && (
                        <div className="instagram-story__arrow-left" onClick={prevSlide}>
                            <Icon fontSize="large">chevron_left</Icon>
                        </div>
                    )}
                    {currentIndex < totalItems - 1 && (
                        <div className="instagram-story__arrow-right" onClick={nextSlide}>
                            <Icon fontSize="large">chevron_right</Icon>
                        </div>
                    )}
                </div>
            )}
            <div className={`instagram-story__gradient-bottom ${isHovered ? 'hidden' : ''}`}></div>
        </div>
    );
};

export default InstagramStoryPreview;
