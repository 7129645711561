import React from 'react';
import classNames from 'classnames';
import BrandSettings from 'components/data/BrandSettings';
import Avatar from 'components/ui-components-v2/Avatar';
import iconSponsored from '../images/icon-sponsored.svg';
import iconSponsoredWhite from '../images/icon-sponsored-white.svg';
import iconMoreHorizWhite from '../images/icon-more-horiz-white.svg';
import iconMoreHorizGrey from '../images/icon-more-horiz-grey.svg';
import FallbackLogo from '../../../../../../../../static/fallback-logo-cape.png';

import '../styles/sponsored-header.scss';

interface SponsoredHeaderProps {
    hasMenu?: boolean;
    dark?: boolean;
    transparent?: boolean;
}

const SponsoredHeader: React.FC<SponsoredHeaderProps> = ({ hasMenu = true, dark = false, transparent = false }) => {
    const advertiser = BrandSettings.getAdvertiserData();

    return (
        <div
            className={classNames('sponsored-header', {
                'sponsored-header--dark': dark,
                'sponsored-header--transparent': transparent
            })}>
            <Avatar src={advertiser?.logo || FallbackLogo} className="sponsored-header__avatar" />
            <div className="sponsored-header__info">
                <div className={classNames('sponsored-header__name', { 'sponsored-header__name--dark': dark })}>{advertiser?.name || 'Cape'}</div>
                <div className={classNames('sponsored-header__text', { 'sponsored-header__text--dark': dark })}>
                    <span className="sponsored-header__text__copy">Sponsored</span> &bull;{' '}
                    <span className="sponsored-header__text__globe-icon">
                        <img src={dark ? iconSponsoredWhite : iconSponsored} alt="Sponsored Icon" />
                    </span>
                </div>
            </div>
            {hasMenu ? <img src={dark ? iconMoreHorizWhite : iconMoreHorizGrey} alt="Sponsored Icon" /> : null}
        </div>
    );
};

export default SponsoredHeader;
