import React from 'react';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { CalculatedGridItem } from 'helpers/arrangeItemsInGrid';
import '../styles/item-wrapper-header.scss';

interface Props {
    zoom: number;
    creativeGridItem: CalculatedGridItem<CreativeV2Enriched>;
    headerComponent?: React.ReactElement; // You can pass a custom header component
}

const ItemWrapperHeader: React.FC<Props> = ({ zoom, creativeGridItem, headerComponent }) => {
    const formatKey = creativeGridItem.itemKey;

    // Additional style for the header to make sure it looks the same when the rest is zoomed in/out
    const getAdditionalStyle = () => {
        return {
            transform: `scale(${1 / zoom})`,
            width: `${zoom * 100}%`,
            marginBottom: `${40 / zoom - 40}px`
        };
    };

    return (
        <div className="creative-item-wrapper-header" style={getAdditionalStyle()}>
            {headerComponent && React.cloneElement(headerComponent, { title: creativeGridItem.item.title, formatKey, scale: creativeGridItem.scale })}
            {!headerComponent && (
                <>
                    <div className="creative-item-wrapper-header__title">
                        <Tooltip title={creativeGridItem.item.title} placement="top">
                            <span>{creativeGridItem.item.title}</span>
                        </Tooltip>
                    </div>
                    {creativeGridItem.scale && creativeGridItem.scale < 1 && (
                        <Tooltip title={`This format is automatically scaled to ${(creativeGridItem.scale * 100).toFixed(0)}% of its original size`}>
                            <div className="creative-item-wrapper-header__scale">{`${(creativeGridItem.scale * 100).toFixed(0)}%`}</div>
                        </Tooltip>
                    )}
                </>
            )}
        </div>
    );
};

export default ItemWrapperHeader;
