import React from 'react';
import { ILinkedinData, LinkedinPlacementKey } from '../types/linkedin-preview.type';
import LinkedinFeedPreview from '../components/linkedin-feed-preview';

export const LinkedinFactory = (placementKey: LinkedinPlacementKey, props: ILinkedinData) => {
    switch (placementKey) {
        case 'feed':
            return <LinkedinFeedPreview {...props} />;
        // cases for other ad types
        default:
            return <div>new linkedin ad</div>;
    }
};
